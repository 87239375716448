$nav-height:150px;
$nav-height-no-image:100px;
$nav-height-mobile:80px;
header {
    display: inline-block;
    width: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: $nav-z-index;
    @include respond-to(phone-max) {
        //height: 100vh;
        min-height: $nav-height-mobile;
        //overflow: hidden;
    }
    &.no-image {
        position: relative;
        background-color: $color_text_dark;
        height: 100px;
        @include respond-to(tablet-max) {
            //height: 100vh;
            height: $nav-height-mobile;
            background-color: transparent;
            position: absolute;
        }
    }
}

.logo {
    float: left;
    display: inline-block;
    height: $nav-height;
    width: 160px;
    z-index: $nav-z-index;
    position: relative;
    @include respond-to(tablet-max) {
        position: absolute;
        width: 85px;
        height: $nav-height-mobile;
    }
    a {
        svg {
            display: inline-block;
            height: 100%;
            width: 100%;
        }
    }
}

.nav {
    display: inline-block;
    float: right;
    height: $nav-height;
    transition: 0.3s ease-out;
    width: 100%;
    .no-image & {
        height: $nav-height-no-image;
    }
    @include respond-to(tablet-max) {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: $nav-height-mobile;
        text-align: center;
        overflow: hidden;
    }
    &-toggle {
        display: inline-block;
        width: 25px;
        height: 25px;
        padding: 0px;
        background-color: transparent;
        border: none;
        display: none;
        @include respond-to(tablet-max) {
            display: inline-block;
            position: absolute;
            right: 20px;
            top: 20px;
        }
        .close-svg {
            display: none;
        }
        svg {
            display: inline-block;
            width: 100%;
            height: 100%;
            fill: white;
            .no-image & {
                fill: $color_text_dark;
            }
        }
    }
    &-list {
        display: inline-block;
        height: auto;
        padding: 0px;
        margin: 0px;
        list-style-type: none;
        margin-top: 60px;
        float: right;
        position: relative;
        z-index: 1;
        -webkit-overflow-scrolling: touch;
        .no-image & {
            margin-top: 40px;
            @include respond-to(phone-max) {
                margin-top: 80px;
            }
        }
        @include respond-to(tablet-max) {
            margin-top: $nav-height-mobile;
            padding-top: $nav-height-mobile;
            overflow: auto;
            height: 540px;
        }
        @include respond-to(phone-max) {
            height: 410px;
        }
        @include respond-to(iphone-5) {
            height: 350px;
            padding-top: 40px;
        }
        @include respond-to(landscape) {
            margin-top: $nav-height-mobile;
            padding-top: 20px;
            height: 200px;
        }
        @media (max-height:320px) and (orientation:landscape) {
            padding-top: 0px;
            height: 180px;
        }
        &-item {
            float: left;
            margin: 0 36px 0 0;
            position: relative;
            height: 50px;
            color: white;
            .no-image &.active {
                &:after {
                    content: " ";
                    display: inline-block;
                    width: 100%;
                    height: 5px;
                    background-color: $color_primary;
                    margin-top: 31px;
                }
            }
            @include respond-to(tablet-max) {
                margin: 0 0 45px 0;
                display: inline-block;
                width: 100%;
                height: auto;
                overflow: hidden;
                &:last-of-type {
                    margin: 0px;
                }
                .no-image &.active {
                    &:after {
                        display: none;
                    }
                }
            }
            @include respond-to(landscape) {
                margin: 0 0 15px 0;
            }
            a {
                font-weight: bold;
                color: inherit;
                font-size: 16px;
                display: inline-block;
                line-height: 1.5;
                &:hover {
                    text-decoration: none;
                    cursor: pointer;
                }
                @media (max-width:1200px) {
                    font-size: 13px;
                }
            }
            &:last-of-type {
                margin-right: 0px;
            }
            &-submenu {
                display: inline-block;
                list-style-type: none;
                padding: 0px;
                margin: 0px;
                position: absolute;
                left: 0px;
                top: 99%;
                padding: 30px;
                background-color: white;
                box-shadow: 0 9px 19px 0 rgba(0, 0, 0, 0.28);
                transition: opacity 0.3s ease-out;
                opacity: 0.0;
                pointer-events: none;
                z-index: 2;
                @include respond-to(tablet-max) {
                    position: relative;
                    padding: 0px;
                    box-shadow: none;
                    opacity: 1.0;
                    max-height: 0px;
                    overflow: hidden;
                    background-color: transparent;
                    transition: max-height 0.3s ease-out;
                    float: left;
                }
                &:before {
                    content: "";
                    width: 10px;
                    height: 10px;
                    position: absolute;
                    left: 20px;
                    top: 0px;
                    transform: translate3d(0, -50%, 0) rotate(45deg);
                    background-color: white;
                    @include respond-to(tablet-max) {
                        display: none;
                    }
                }
                &-item {
                    display: inline-block;
                    width: 100%;
                    white-space: nowrap;
                    margin: 0 0 25px 0;
                    display: inline-block;
                    line-height: 1.5;
                    &:last-of-type {
                        margin-bottom: 0px;
                    }
                    @include respond-to(tablet-max) {
                        margin: 25px 0 0 0;
                    }
                    @include respond-to(landscape) {
                        margin: 15px 0 0 0;
                    }
                    a {
                        display: inline-block;
                        width: 100%;
                        font-size: 12px;
                        font-weight: 600;
                        color: $color_text_dark;
                        @include respond-to(tablet-max) {
                            color: #838383;
                        }
                    }
                }
            }
            &:hover {
                .nav-list-item-submenu {
                    opacity: 1.0;
                    pointer-events: initial;
                }
            }
            &.open {
                .nav-list-item-submenu {
                    @include respond-to(tablet-max) {
                        box-shadow: none;
                        max-height: 400px;
                    }
                }
            }
            &.active {
                @include respond-to(tablet-max) {
                    color: $color_primary;
                }
            }
        }
    }
    &-social-list {
        display: inline-block;
        width: 100%;
        height: 34px;
        list-style-type: none;
        text-align: center;
        padding: 0px;
        margin: 30px 0 0 0;
        display: none;
        position: relative;
        z-index: 1;
        &-item {
            display: inline-block;
            width: 34px;
            height: 34px;
            margin-right: 25px;
            &:last-of-type {
                margin-right: 0px;
            }
            a {
                display: inline-block;
                width: 100%;
                height: 100%;
                svg {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                }
            }
        }
        @include respond-to(tablet-max) {
            display: inline-block;
        }
        @include respond-to(phone-max) {
            margin-top: 20px;
        }
        @include respond-to(landscape) {
            margin-top: 30px;
        }
        @media (max-height:320px) and (orientation:landscape) {
            margin-top: 10px;
        }
    }
    &:before {
        content: "";
        display: inline-block;
        position: fixed;
        width: 100vw;
        height: 0px;
        left: 0px;
        top: -1px;
        z-index: 0;
        background-color: $color_text_dark;
        transition: 0.3s ease-out;
        opacity: 0.0;
    }
    &.open {
        @include respond-to(phone-max) {
            background-color: $color_text_dark;
        }
        @include respond-to(only-tablet) {
            &:before {
                height: 101vh;
                opacity: 1;
            }
        }
        @include respond-to(tablet-max) {
            height: 100vh;
            overflow: scroll;   //iphone landscape scroll not visible issue fix
            .nav-toggle {
                .menu-svg {
                    display: none;
                    color: #fff;
                }
                .close-svg {
                    display: inline-block;
                }
            }
        }
    }
}