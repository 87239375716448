//////////// Careers Page //////////////////
.licensed-aircraft-engineers {
    //using 2x for both as we don't have high res image for this
    background-image: url("/images/careers/licensed-aircraft-engineers@2x.jpg");
    @include retina {
        background-image: url("/images/careers/licensed-aircraft-engineers@2x.jpg")
    }
}

.aircraft-technicans {
    background-image: url("/images/careers/aircraft-technicans@1x.jpg");
    @include retina {
        background-image: url("/images/careers/aircraft-technicans@2x.jpg")
    }
}

.support {
    background-image: url("/images/careers/support@1x.jpg");
    @include retina {
        background-image: url("/images/careers/support@2x.jpg")
    }
}


.apprenticeship {
    background-image: url("/images/careers/apprenticeship@1x.jpg");
    @include retina {
        background-image: url("/images/careers/apprenticeship@2x.jpg")
    }
}

.apprenticeship-old {
    background-image: url("/images/careers/apprenticeship-old@1x.jpg");
    @include retina {
        background-image: url("/images/careers/apprenticeship-old@2x.jpg")
    }
}


//////////// End Careers Page ///////////////
.engineering {
    background-image: url("/images/banners/engineering-1@1x.jpg");
    @include retina {
        background-image: url("/images/banners/engineering-1@2x.jpg")
    }
}

.plane-1 {
    background-image: url("/images/banners/engineering-2@1x.jpg");
    @include retina {
        background-image: url("/images/banners/engineering-2@2x.jpg")
    }
}

.plane-2 {
    background-image: url("/images/banners/engineering-3@1x.jpg");
    @include retina {
        background-image: url("/images/banners/engineering-3@2x.jpg")
    }
}

.careers {
    background-image: url("/images/banners/careers@1x.jpg");
    @include retina {
        background-image: url("/images/banners/careers@2x.jpg")
    }
}



.bg-plane {
    background-image: url("/images/content/plane@1x.jpg");
    @include retina {
        background-image: url("/images/content/plane@2x.jpg")
    }
}

.bg-airport {
    background-image: url("/images/content/airport@1x.jpg");
    @include retina {
        background-image: url("/images/content/airport@2x.jpg")
    }
}

.bg-captain {
    &:after {
        background-image: url("/images/content/captain@1x.jpg");
        @include retina {
            background-image: url("/images/content/captain@2x.jpg")
        }
    }
}

.plane-4
{
    background-image: url("/images/banners/engineering-4@1x.jpg");
    @include retina {
        background-image: url("/images/banners/engineering-4@2x.jpg")
    }
}

///////

.engineering-1
{
    background-image: url("/images/content/engineering-1@1x.jpg");
    @include retina {
        background-image: url("/images/content/engineering-1@2x.jpg")
    }
}

.engineering-2
{
    background-image: url("/images/content/engineering-2@1x.jpg");
    @include retina {
        background-image: url("/images/content/engineering-2@2x.jpg")
    }
}

.engineering-3
{
    background-image: url("/images/content/engineering-3@1x.jpg");
    @include retina {
        background-image: url("/images/content/engineering-3@2x.jpg")
    }
}

.engineering-4
{
    background-image: url("/images/content/engineering-4@1x.jpg");
    @include retina {
        background-image: url("/images/content/engineering-4@2x.jpg")
    }
}

.engineering-5
{
    background-image: url("/images/content/engineering-5@1x.jpg");
    @include retina {
        background-image: url("/images/content/engineering-5@2x.jpg")
    }
}

.engineering-6
{
    background-image: url("/images/content/engineering-6@1x.jpg");
    @include retina {
        background-image: url("/images/content/engineering-6@2x.jpg")
    }
}
.engineering-7
{
    background-image: url("/images/content/engineering-7@1x.jpg");
    @include retina {
        background-image: url("/images/content/engineering-7@2x.jpg")
    }
}
.engineering-8
{
    background-image: url("/images/content/engineering-8@1x.jpg");
    @include retina {
        background-image: url("/images/content/engineering-8@2x.jpg")
    }
}
.engineering-9
{
    background-image: url("/images/content/engineering-9@1x.jpg");
    @include retina {
        background-image: url("/images/content/engineering-9@2x.jpg")
    }
}
.engineering-10
{
    background-image: url("/images/content/engineering-10@1x.jpg");
    @include retina {
        background-image: url("/images/content/engineering-10@2x.jpg")
    }
}
.engineering-11
{
    background-image: url("/images/content/engineering-11@1x.jpg");
    @include retina {
        background-image: url("/images/content/engineering-11@2x.jpg")
    }
}
.engineering-12
{
    background-image: url("/images/content/engineering-12@1x.jpg");
    @include retina {
        background-image: url("/images/content/engineering-12@2x.jpg")
    }
}
.engineering-13
{
    background-image: url("/images/content/engineering-13@1x.jpg");
    @include retina {
        background-image: url("/images/content/engineering-13@2x.jpg")
    }
}
.engineering-14
{
    background-image: url("/images/content/engineering-14@1x.jpg");
    @include retina {
        background-image: url("/images/content/engineering-14@2x.jpg")
    }
}
.engineering-15
{
    background-image: url("/images/content/engineering-15@1x.jpg");
    @include retina {
        background-image: url("/images/content/engineering-15@2x.jpg")
    }
}
.engineering-16
{
    background-image: url("/images/content/engineering-16@1x.jpg");
    @include retina {
        background-image: url("/images/content/engineering-16@2x.jpg")
    }
}
.engineering-17
{
    background-image: url("/images/content/engineering-17@1x.jpg");
    @include retina {
        background-image: url("/images/content/engineering-17@2x.jpg")
    }
}
.engineering-18
{
    background-image: url("/images/content/engineering-18@1x.jpg");
    @include retina {
        background-image: url("/images/content/engineering-18@2x.jpg")
    }
}
.engineering-19
{
    background-image: url("/images/content/engineering-19@1x.jpg");
    @include retina {
        background-image: url("/images/content/engineering-19@2x.jpg")
    }
}
.engineering-20
{
    background-image: url("/images/content/engineering-20@1x.jpg");
    @include retina {
        background-image: url("/images/content/engineering-20@2x.jpg")
    }
}
.engineering-21
{
    background-image: url("/images/content/engineering-21@1x.jpg");
    @include retina {
        background-image: url("/images/content/engineering-21@2x.jpg")
    }
}
.engineering-22
{
    background-image: url("/images/content/engineering-22@1x.jpg");
    @include retina {
        background-image: url("/images/content/engineering-22@2x.jpg")
    }
}
.engineering-23
{
    background-image: url("/images/content/engineering-23@1x.jpg");
    @include retina {
        background-image: url("/images/content/engineering-23@2x.jpg")
    }
}
