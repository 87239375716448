.capability {
    padding: 75px 0;
    overflow: hidden;
    @include respond-to(phone-max) {
        padding: 60px 0 30px 0;
    }
    &-select-wrapper {
        display: inline-block;
        width: 100%;
        position: relative;
        border: solid 1px #d2d2d2;
        margin-top: 40px;
        svg {
            display: inline-block;
            width: 20px;
            height: 10px;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translate3d(0, -50%, 0);
            fill: $color_primary;
        }
    }
    &-select {
        display: inline-block;
        width: 100%;
        -webkit-appearance: none;
        padding: 15px 20px;
        background-color: white;
        border: none;
        border-radius: 0px;
        margin-bottom: 0px;
    }
    &-tabs {
        position: relative;
        z-index: 2;
        margin-top: 55px;
        @include respond-to(phone-max) {
            margin-top: 35px;
            transform: translate3d(0, 20px, 0);
        }
        &-button {
            font-size: 20px;
            font-weight: 400;
            display: inline-block;
            width: 100%;
            border: none;
            color: $color_text_dark;
            outline: none;
            text-transform: uppercase;
            background-color: transparent;
            padding: 0 0 30px 0;
            border-bottom: solid 6px transparent;
            transition: 0.3s ease-out;
            @include respond-to(phone-max) {
                font-size: 15px;
                padding: 5px 0;
            }
            &.active {
                color: $color_primary;
                border-bottom: solid 6px $color_primary;
            }
        }
    }
    &-list {
        small {
            color: $color_text;
            display: inline-block;
            margin-top: 20px;
        }
        .mobile-scroll {
            @include respond-to(phone-max) {
                padding: 20px 15px;
                overflow-x:scroll;
                -webkit-overflow-scrolling: touch;
                -ms-overflow-style:-ms-autohiding-scrollbar;
            }
        }
        &-table {
            display: inline-block;
            width: 100%;
            height: auto;
            padding: 30px;
            box-shadow: 0px 0px 16px 0 rgba(0, 0, 0, 0.24);
            position: absolute;
            top: 0px;
            opacity: 0.0;
            z-index: -1;
            margin-top: 50px;
            &.no-margin-top {
                margin-top: 0px;
            }
            @include respond-to(laptop-min) {
                width: 100%;
                margin-left: -20px;
                // width: calc(100% + 60px);
                // margin-left: -30px;
            }
            @include respond-to(phone-max) {
                min-width: 480px;
            }
            table {
                table-layout: fixed;
                width: 100%;
                font-size: 16px;
                height: 0px;
                vertical-align: top;
                padding: 30px;
                @include respond-to(tablet-max) {
                    font-size: 14px;
                }
                thead,
                tbody {
                    width: 100%;
                    tr {
                        width: 100%;
                        border-bottom: solid 1px #d2d3d7;
                        &:first-of-type {
                            border-top: solid 1px #d2d3d7;
                        }
                    }
                }
                th,
                td {
                    height: 50px;
                    line-height: 50px;
                    border-right: solid 1px #d2d3d7;
                    text-align: center;
                    font-weight: 400;
                    position: relative;
                    word-break: break-word;
                    &.single-row {
                        padding: 20px 60px;
                        @include respond-to(phone-max) {
                            padding: 0;
                        }
                    }
                    &:first-of-type {
                        text-align: left;
                        @include respond-to(phone-max) {
                            width: 150px;
                        }
                        &.text-center {
                            text-align: center;
                        }
                    }
                    span {
                        display: block;
                        line-height: 1.2;
                    }
                    &.no-border {
                        border-right: none;
                    }
                    &.red {
                        color: $color_primary;
                    }
                    &:last-of-type {
                        border-right: none;
                    }
                }
                td {
                    position: relative;
                    &.active-with-text {
                      text-indent: 25px;

                      &:after {
                          content: "";
                          display: inline-block;
                          width: 14px;
                          height: 14px;
                          position: absolute;
                          left: 50%;
                          top: 50%;
                          transform: translate3d(-50%, -50%, 0);
                          border-radius: 50%;
                          background-color: $color_primary;
                      }
                    }
                    &.active {
                        text-indent: -9999px;
                        &:after {
                            content: "";
                            display: inline-block;
                            width: 14px;
                            height: 14px;
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate3d(-50%, -50%, 0);
                            border-radius: 50%;
                            background-color: $color_primary;
                        }

                    }
                }
            }
            @include respond-to(phone-max) {
                &.sm-one-row {
                    tbody {
                        tr {
                            display: none;
                            &.active {
                                display: table-row;
                            }
                        }
                    }
                }
            }
            &.active {
                opacity: 1.0;
                z-index: 1;
                padding: 35px;
                height: auto;
                position: relative;
                @include respond-to(phone-max) {
                    min-width: 35px 10px;
                }
            }
            &.training {
                table {
                    th,
                    td {
                        vertical-align: top;
                        padding: 20px;
                        text-align: left;
                        span {
                            line-height: 1.4;
                        }
                    }
                }
            }
            &.longText {
                table {
                  th,
                  td {
                    vertical-align: top;
                    padding: 20px;
                    text-align: left;
                    font-size: 13px;
                    span {
                        line-height: 1.4;
                    }
                  }
                }
                table tr td:first-child{
                  width:200px;
                }
            }
        }
    }
}
