.form {
    &-header {
        background-color: $color_background;
        padding: 70px 0;
        &-title {
            margin-top: 90px;
            margin-bottom: 20px;
        }
        &-text {
            color: $color_text;
            margin: 0px;
        }
        .select-wrapper {
            margin-top: 30px;
        }
    }
    &-body {
        background-color: white;
        padding: 70px 0;
    }
    &-legend {
        font-size: 36px;
        margin: 40px 0 50px 0;
        &.no-margin-top {
            margin-top: 0px;
        }
    }
    .select-wrapper {
        border: solid 1px $color_border_gray_dark;
    }
    .form-type {
        display: none;
        &.active {
            display: block;
        }
    }
    .button {
        margin-top: 10px;
        position: relative;
        overflow: hidden;
        span {
            position: relative;
            z-index: 1;
        }
        &:before {
            content: " ";
            background-color: #941116;
            display: inline-block;
            opacity: 0.5;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0px;
            top: 0px;
            z-index: 0;
            transform: translate3d(-100%, 0, 0);
        }
        &.loading {
            pointer-events: none;
            &:before {
                animation: loadingButton 5s ease-out infinite;
            }
        }
    }
    &-feedback-message {
        display: inline-block;
        width: 100%;
        font-size: 18px;
        margin-top: 40px;
        height: 20px;
        font-family: "Emirates-Bold", "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        &.fail,
        &.error {
            color: red;
        }
        &.success {
            color: green;
        }
    }
}


@keyframes loadingButton {
    0% {
        transform: translate3d(-100%, 0, 0);
    }
    100% {
        transform: translate3d(100%, 0, 0);
    }
}


.select-wrapper {
    display: inline-block;
    border: solid 1px $color_border_gray;
    background-color: white;
    width: 100%;
    position: relative;
    height: 50px;
    .select {
        display: inline-block;
        width: 100%;
        padding: 13px 40px 13px 22px;
        line-height: 24px;
        border: none;
        -webkit-appearance: none;
        position: relative;
        z-index: 1;
        background-color: transparent;
        -moz-appearance: none;
        &::-ms-expand {
            display: none;
        }
    }
    svg.arrow {
        display: inline-block;
        width: 16px;
        height: 8px;
        fill: $color_primary;
        position: absolute;
        right: 22px;
        top: 50%;
        transform: translate3d(0, -50%, 0);
    }
}

.form-input {
    display: inline-block;
    width: 100%;
    margin-bottom: 10px;
    float: left;
    padding-bottom: 25px;
    position: relative;
    &-error {
        input {
            border: solid 1px red;
        }
        .form-input-message {
            opacity: 1.0;
            pointer-events: initial;
        }
    }
    &-message {
        display: inline-block;
        width: 100%;
        position: absolute;
        left: 0px;
        bottom: 0px;
        min-height: 20px;
        margin-top: 5px;
        color: red;
        font-size: 12px;
        opacity: 0.0;
        pointer-events: none;
        float: left;
    }
}

label {
    display: block;
    margin-bottom: 20px;
    color: $color_text;
    font-size: 14px;
    line-height: 24px;
    font-weight: bold;
}

input,
textarea {
    display: inline-block;
    width: 100%;
    height: 50px;
    border: solid 1px $color_border_gray_dark;
    padding: 12px 22px;
}

textarea {
    resize: none;
    height: 150px;
}

fieldset {
    margin: 0px;
    padding: 0px;
    border: none;
}

.hide {
    display: none;
}


.contact {
    display: inline-block;
    width: 100%;
    position: relative;
    margin-bottom: 25px;
    text-decoration: none;
    color: $color_text;
    &:active,
    &:visited,
    &:focus,
    &:hover {
        color: $color_text;
        text-decoration: none;
    }

    &-wrapper {
        margin-top: 130px;
        @include respond-to(phone-max) {
            margin-top: 80px;
        }
    }
    &-svg {
        display: inline-block;
        width: 22px;
        height: 22px;
        float: left;
        position: absolute;
        left: 0px;
        top: 50%;
        transform: translate3d(0, -50%, 0);
    }
    &-text {
        display: inline-block;
        float: left;
        margin-left: 35px;
        line-height: 20px;
    }
}

.g-recaptcha {
    display:inline-block;
    width:100%;
    margin-top: 30px;
}