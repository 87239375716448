.sidebar {
    padding: 90px 0;
    @include respond-to(phone-max) {
        padding: 60px 0 50% 0; /*changed by ravi padding: 60px 0 140px 0;*/
    }
    &-list {
        padding: 0px;
        list-style-type: none;
        padding-right: 5%;
        @include respond-to(laptop-min) {
            padding-right: 15%;/*changed by Ghanshyam due to compilance monitoring padding-right: 20%;*/
        }

        &-item {
            margin-bottom: 20px;
            &:last-of-type {
                margin-bottom: 0px;
            }
            a {
                font-size: 16px;
                font-weight: bold;
                color: $color_text;
            }
            &.active {
                a {
                    color: $color_primary;
                }
            }
        }
    }
    &-select-wrapper {
        display: inline-block;
        width: 100%;
        position: relative;
        border: solid 1px #d2d2d2;
        margin-bottom: 45px;
        svg {
            display: inline-block;
            width: 20px;
            height: 10px;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translate3d(0, -50%, 0);
            fill: $color_primary;
        }
    }
    &-select {
        display: inline-block;
        width: 100%;
        -webkit-appearance: none;
        padding: 15px 20px;
        background-color: white;
        border: none;
        border-radius: 0px;
        line-height: 1.6;
    }
    &-view-map {
        display: inline-block;
        width: 90%;
        margin-top: 60px;
        background-color: $color_primary;
        padding: 15px;
        color: white;
        font-family: "Emirates-Bold";
        font-size: 19px;
        font-weight: bold;
        transition: 0.3s ease-out;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right center;
        background-image: url("/images/map/world-map@1x.png");
        @include retina {
            background-image: url("/images/map/world-map@2x.png");
        }
        &:hover,
        &:active,
        &:visited {
            color: white;
            box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.3);
        }
        @include respond-to(laptop-min) {
            width: 70%;
        }
        @include respond-to(phone-max) {
            position: absolute;
            left: 15px;
            bottom: -1%; /*-5%,0px*/
            transform: translate3d(0, 70%, 0);
            width: 70%;
            @media(orientation:landscape){
                bottom: -5%;
            }
        }        
    }
    &-content {
        margin-bottom: 60px; /*100px*/
        &:last-of-type {
            margin-bottom: 0px;
        }
        @include respond-to(phone-max) {
            //display: none;
            //margin-bottom: 20px;/*100px*/
            &.active {
                display: block;
            }
        }
        &-header {
            color: $color_text_dark;
            margin-bottom: 20px;
        }
        &-text {
            color: $color_text;
        }
        &-list {
            padding-left: 20px;
            margin: 0px;
            list-style-type: square;
            color: $color_text;
            li {
                line-height: 1.67;
                color: $color_text;
                margin-bottom: 20px;
                &:last-of-type {
                    margin-bottom: 0px;
                }
            }
        }
    }
}
.sidebar-Affair-list-item
{
 line-height: 1.5;;
}