/* ==========================================================================
	Directions
/* ========================================================================== */

$start: left;
$end: right;
$margin-start: margin-left;
$margin-end: margin-right;


/* ==========================================================================
	Variables
/* ========================================================================== */


/* Colors */

$color_text_dark: #3D3D3D;
$color_text: #77787B;
$color_primary: #D71920;
$color_opposite: #2D2E31;
$color_background: #F4F4F4;
$color_backgorund_dark: #77787B;
$color_border_gray: #D2D2D2;
$color_border_gray_dark:#979797;


/* Font Sizes */

$size_base: 1em;

$nav-z-index:5;
$hero-content-z-index:2;

/* ==========================================================================
	Responsive
/* ========================================================================== */

$phone-min-size: 320px;
$phone-max-size: 767px;
$tablet-min-size: 768px;
$tablet-max-size: 991px;
$laptop-min-size: 992px;
$laptop-max-size: 1279px;
$desktop-min-size: 1280px;
$desktop-max-size: 2520px;
