.map {
    position: relative;
    padding: 75px 0;
    &.only-map {
        padding: 120px 0;
    }
    &-header {
        margin-bottom: 00px;
    }
    &-container {
        display: inline-block;
        width: 100%;
        height: 650px;
        .map-city-name {
            font-size: 16px;
            font-weight: bold;
            line-height: 1.62;
            color: $color_text_dark;
            position: absolute;
            left: 50%;
            transform: translate3d(-50%, 0, 0);
            white-space: nowrap;
            bottom: 0px;
        }
        .gm-style-iw {
            position: relative !important;
            top: 12px !important;
            overflow: visible;
            &+div {
                display: none;
            }
        }
    }
    &-network {
        position: absolute;
        top: 200px;
        width: 340px;
        height: 600px;
        background-color: #f3f3f3;
        box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.22);
        z-index: 2;
        overflow: hidden;
        @include respond-to(tablet-max) {
            width: 250px;
        }
        @include respond-to(phone-max) {
            position: relative;
            width: 100%;
            top: auto;
            margin-top: 50px;
        }
        &-list {
            padding: 0px;
            margin: 0px;
            list-style-type: none;
            transition: opacity 0.3s ease-out;
            &-item {
                display: inline-block;
                width: 100%;
                a {
                    display: inline-block;
                    width: 100%;
                    font-family: "Emirates-Bold";
                    padding: 10px 0;
                    font-size: 26px;
                    font-weight: bold;
                    color: $color_primary;
                }
                &:hover {
                    cursor: pointer;
                }
                &-cities {
                    padding: 0px;
                    margin: 0px;
                    list-style-type: none;
                    width: calc(100% + 60px);

                    background-color: white;
                    margin: 0px;
                    max-height: 0px;
                    overflow: scroll;
                    transition: max-height 0.3s ease-out;
                    margin-left: -30px;
                    -webkit-overflow-scrolling: touch;
                    -ms-overflow-style: none;
                    li {
                        a {
                            display: inline-block;
                            font-size: 16px;
                            color: $color_text_dark;
                            padding: 20px 30px;
                            font-family: "Open Sans";
                            transition: background-color 0.3s ease-out;
                        }
                        &.active {
                            a {
                                background-color: $color_primary;
                                color: white;
                            }
                        }
                    }
                }
                &.active {
                    .map-network-list-item-cities {
                        max-height: 310px;
                    }
                }
            }
        }
        .city-details {
            position: absolute;
            left: 100%;
            top: 0px;
            display: inline-block;
            width: 100%;
            height: 100%;
            padding: 30px;
            background-color: white;
            font-family: "Emirates-Bold", "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
            &-back-button {
                font-size: 16px;
                font-weight: bold;
                color: $color_primary;
                padding-left: 20px;
                position: relative;
                display: block;
                margin-top: 20px;

                svg.arrow {
                    display: inline-block;
                    width: 15px;
                    height: 15px;
                    position: absolute;
                    left: 0px;
                    top: 50%;
                    transform: translate3d(-50%, -50%, 0) rotate(90deg);
                    fill: $color_primary;
                }
            }
            &-title {
                display: block;
                margin-top: 45px;
                font-size: 26px;
                font-weight: bold;
                color: $color_primary;
            }
            &-name {
                display: block;
                font-size: 16px;
                color: $color_text_dark;
                margin-top: 20px;
                font-weight: bold;
            }
            &-text {
                display: block;
                margin: 20px 0;
                font-size: 15px;
                color: $color_text;
            }
            &-tel,
            &-mobile,
            &-fax,
            &-email {
                display: block;
                margin-top: 2px;
                font-size: 15px;
                color: $color_text;
            }
            &-email-label {
                display: block;
                margin-top: 15px;
                font-size: 15px;
                color: $color_text;
            }
        }
        &.details {
            height: 230px;
            .map-network-wrapper {
                transform: translate3d(-100%, 0, 0);
            }
            .map-network-list {
                opacity: 0.0;
            }
        }
        &-wrapper {
            position: relative;
            display: inline-block;
            width: 100%;
            height: 100%;
            padding: 30px;
            transition: 0.3s ease-in-out;
        }
    }
}