.footer {
    display: inline-block;
    width: 100%;
    text-align: center;
    background-color: $color_backgorund_dark;
    float: left;
    padding-bottom: 50px;
    @include respond-to(phone-max) {
        height: auto;
        padding-bottom: 40px;
    }
    @include respond-to(iphone-5) {
        height: auto;
    }
    &-logo {
        svg {
            display: inline-block;
            width: 126px;
            height: 118px;
            transform: translate3d(0, -50%, 0);
        }
    }
    &-nav {
        &-list {
            margin: 0px;
            padding: 0px;
            list-style-type: none;
            @include respond-to(phone-max) {
                text-align: left;
            }
            @include respond-to(landscape) {
                text-align: center;
            }
            &-item {
                display: inline-block;
                margin-left: 17px;
                margin-right: 17px;
                @include respond-to(tablet-max) {
                    margin-bottom: 20px;
                }
                @include respond-to(phone-max) {
                    margin-right: 20px;
                    margin-left: 0px;
                    margin-bottom: 0px;
                }
                a {
                    color: white;
                    font-size: 14px;
                    font-weight: bold;
                    @include respond-to(phone-max) {
                        line-height: 4.36;
                    }
                }
            }
        }
    }
    &-rights {
        font-size: 14px;
        color: white;
        margin-top: 35px;
        span {
            line-height: 1.2;
            @include respond-to(phone-max) {
                line-height: 1.4;
            }
        }
        @include respond-to(phone-max) {
            margin-top: 60px;
            text-align: left;
        }
        @include respond-to(landscape) {
            text-align: center;
            margin-top: 20px;
        }
    }
    &-social {
        margin-top: 40px;
        @include respond-to(phone-max) {
            margin-top: 75px;
        }
        @include respond-to(landscape) {
            text-align: center;
            margin-top: 30px;
        }
        &-list {
            margin: 0px;
            padding: 0px;
            list-style-type: none;
            &-item {
                display: inline-block;
                width: 24px;
                height: 24px;
                margin-right: 18px;
                &:last-of-type {
                    margin-right: 0px;
                }
                @include respond-to(phone-max) {
                    width: 35px;
                    height: 35px;
                }
                svg {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}