.button {
    display: inline-block;
    padding: 20px 30px;
    background-color: $color_primary;
    border: none;
    color: white;
    text-transform: none;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    transition: background-color 0.3s ease-out;
    appearance: none;
    box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.3);
    @include respond-to(phone-max) {
        font-size: 13px;
        padding: 20px 25px;
    }
    &:hover,
    &:active,
    &:focus,
    &:visited {
        color: white;
        text-decoration: none;
    }
    &:hover {
        background-color: white;
        color: $color_primary;
    }
    &.loading {
        color: white;
        background-color: $color_primary;
    }
    &.gray {
        background-color: $color_opposite;
        &:hover {
            background-color: white;
            color: $color_opposite;
        }
    }
}