.customers {
    text-align: center;
    padding: 80px 0 130px 0;
    &-header {
        margin-bottom: 20px;
    }
    &-text {
        padding: 0 30px;
    }
    &-list {
        display: inline-block;
        width: 100%;
        margin-top: 50px;
        overflow: hidden;
        .slick-list {
            display: inline-block;
            width: 100%;
            height: 100%;
        }
        .slick-dots {
            position: absolute;
            bottom: 0px;
            left: 50%;
            transform: translate3d(-50%, 0, 0);
            margin: 0px;
            padding: 0px;
            li {
                button {
                    opacity: 1.0;
                    background-color: $color_backgorund_dark;
                }
            }
            .slick-active {
                button {
                    background-color: $color_primary;
                }
            }
        }
        &-slide {
            display: inline-block;
            width: 100%;
            height: 100%;
            float: left;
            text-align: center;
            outline: none;
            &-logos {
                padding: 0px;
                margin: 0px;
                list-style-type: none;
                li {
                    display: inline-block;
                    width: 20%;
                    height: 100px;
                    float: left;
                    padding: 0 30px;
                    margin-bottom: 30px;
                    @include respond-to(phone-max) {
                        padding: 0 20px;
                        width: 50%;
                    }
                    a {
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        img {
                            display: inline-block;
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                }
            }
        }
    }
}