.hero {
    display: inline-block;
    float: left;
    width: 100%;
    height: 100vh;
    min-height: 550px;
    &.less-height {
        @include respond-to(laptop-min) {
            height: 645px;
        }
    }
    &-bg,
    &-slider {
        display: inline-block;
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
        .slick-list {
            display: inline-block;
            width: 100%;
            height: 100%;
            .slick-track {
                display: inline-block;
                width: 100%;
                height: 100%;
            }
        }
        .slick-dots {
            position: absolute;
            bottom: 75px;
            right: calc(50vw - 585px);
            margin: 0px;
            padding: 0px;
            @media (max-width: 1199px) {
                right: calc(50vw - 485px);
            }

            @include respond-to(tablet-max) {
                right: 100px;
            }
            @include respond-to(phone-max) {
                right: 15px;
                bottom: 50px;
            }
        }
        &-item {
            display: inline-block;
            width: 100vw;
            height: 100%;
            .wrapper {
                display: inline-block;
                width: 100%;
                height: 100%;
                position: relative;
                background-size: cover;
                background-position: center bottom;
                &:after {
                    content: "";
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.0));
                }
            }
            &-content {
                position: relative;
                color: white;
                z-index: $hero-content-z-index;
                bottom: 75px;
                margin-top: calc(100vh);
                transform: translateY(-100%);
                @include respond-to(only-tablet) {
                    margin-top: calc(100vh - 100px);
                }
                @include respond-to(landscape) {
                    margin-top: calc(100vh + 50px);
                }
                @include respond-to(only-iphone) {
                    margin-top: calc(150vh);
                }
                @include respond-to(fold) {
                    margin-top: calc(145vh);
                }
                .less-height & {
                    @include respond-to(laptop-min) {
                        margin-top: calc(645px);
                        //margin-top: calc(645px - 75px);
                    }
                }
                &-header {
                    font-size: 40px;
                    margin-bottom: 15px;
                }
                .button {
                    margin-top: 30px;
                    @include respond-to(landscape) {
                        margin-top: 5px;
                    }
                }
                &-text {
                    margin-bottom: 5px;
                    @include respond-to(phone-max) {
                        max-height: 125px;
                        overflow: hidden;
                    }
                }
            }
        }
    }
}
