$p-line-height:1.67;
$body-font-size:15px;


@font-face {
    font-family: 'Emirates-Bold';
    src: local('☺'), url('/fonts/Emirates-Bold.woff2') format('woff2'),
    url('/fonts/Emirates-Bold.woff') format('woff'),
    url('/fonts/Emirates-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Emirates-Medium';
    src: local('☺'), url('/fonts/Emirates-Medium.woff2') format('woff2'),
    url('/fonts/Emirates-Medium.woff') format('woff'),
    url('/fonts/Emirates-Medium.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}


body {
    font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: $body-font-size;
    line-height: 1;
}

body,
input,
textarea,
select,
button {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-font-feature-settings: 'liga', 'kern';
}

h1,
h2,
h3,
h4,
h5,
h6,
legend {
    // font-family: "Emirates-Bold", "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-family: Emirates-Bold,Open Sans,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif;
    font-weight: 600;
    line-height: 1.166;
    margin: 0px;
}

h1 {
    font-size: 40px;
    @include respond-to(phone-max) {
        font-size: 40px;
    }
    small {
        font-size: 0.5em;
        display: block;
        margin-bottom: 1em;
        color: inherit;
        font-weight: inherit;
    }
}

h2 {
    font-size: 36px;
}

h3 {
    font-size: 30px;
    @include respond-to(phone-max) {
        font-size: 30px;
    }
}

h4 {
    font-size: 22px;
}

h5 {
    font-size: 18px;
}

.normal-case {
    text-transform: none;
}

p {
    line-height: $p-line-height;
}