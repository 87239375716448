$bi-app-direction: ltr;
$bi-app-right: right;
$bi-app-left: left;

@import 'settings';
@import 'mixins/mixins';
@import 'bootstrap';
@import 'global';
@import "typography";
@import 'components/buttons';
@import 'components/header';
@import 'components/hero';
@import 'components/footer';
@import 'components/content';
@import 'components/customers-list';
@import 'components/sidebar';
@import 'components/capability';
@import 'components/map';
@import 'components/images';
@import 'components/form';



