html,
body {
    position: relative;
    width: 100%;
    height: 100%;
}

html.no-scroll,
body.no-scroll {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.visually-hidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}


a:hover,
a:active,
a:focus,
a:visited {
    text-decoration: none;
}


section {
    display: inline-block;
    width: 100%;
    float: left;
}


.bg-gray {
    background-color: $color_background;
}


.slick-dots {
    li {
        display: inline-block;
        width: 10px;
        height: 10px;
        float: left;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 14px;
        position: relative;
        &:last-of-type {
            margin-right: 0px;
        }
        button {
            display: inline-block;
            width: 100%;
            height: 100%;
            opacity: 0.2;
            background-color: white;
            transition: scale 0.3s ease-out;
            border-radius: 50%;
            border: none;
            background-color: none;
            text-indent: -9999px;
            padding: 0px;
            position: absolute;
            left: 0px;
            top: 0px;
        }
        &.slick-active {
            transform: scale(1.4);
            button {
                opacity: 1;
            }
        }
    }
}


@include respond-to(phone-max) {
    .sm-static {
        position: static;
    }
    .sm-relative {
        position: relative;
    }
}


.no-margin-top {
    margin-top: 0px;
}

input,
textarea {
    border-radius: 0;
    -webkit-appearance: none;
}

input::-ms-clear {
    display: none;
}