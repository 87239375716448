.content {
    display: inline-block;
    width: 100%;
    padding: 70px 0;
    &.extra-top-padding {
        padding-top: 140px;
    }
    &-header {
        color: $color_text_dark;
        margin-bottom: 20px;
    }
    &-text {
        color: $color_text;
        margin: 0px;
    }
    &.bottom-spacing {
        @include respond-to(phone-max) {
            padding: 70px 0 200px 0;
        }
    }
    .button {
        margin-top: 30px;
    }
    &.min-height {
        min-height: 500px;
    }
}

////////////////////////////////////
.content-bg {
    background-size: cover;
    background-position: center;
    position: relative;
    padding: 90px 0;
    @include respond-to(phone-max) {
        padding: 60px 0;
    }
    &.bottom {
        background-position: center bottom;
    }
    &:after {
        content: "";
        display: inline-block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0px;
        top: 0px;
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0.78), rgba(0, 0, 0, 0.21));
    }
    * {
        z-index: 1;
    }
    .content-header {
        color: white;
    }
    .content-text {
        //margin-top: 20px;
        margin-top: 0px;
        color: white;
    }
    .button {
        margin-top: 30px;
    }
}

////////////////////////////////////
.content-image {
    background-color: $color_background;
    position: relative;
    padding: 95px 0;
    @include respond-to(phone-max) {
        padding: 60px 0;
    }
    &.extra-bottom-padding {
        @include respond-to(phone-max) {
            padding-bottom: 100px !important;
            &:after {
                bottom: 190px;
            }
        }
    }
    &:after {
        content: "";
        display: inline-block;
        width: 50%;
        height: 100%;
        position: absolute;
        right: 0px;
        top: 0px;
        background-size: cover;
        background-position: center;
        @include respond-to(phone-max) {
            display: inline-block;
            top: auto;
            bottom: 150px;
            width: 100%;
            height: 450px;
        }
    }
    .content-text {
        @include respond-to(laptop-min) {
            padding-right: 20%;
        }
    }
    .content-buttons {
        display: inline-block;
        width: 100%;
        @include respond-to(phone-max) {
            margin-top: 490px;
        }
    }
    .button {
        margin-right: 35px;
        @include respond-to(tablet-max) {
            padding: 20px 15px;
            margin-right: 20px;
        }
        @include respond-to(iphone-5) {
            padding: 20px 15px;
        }
        @include respond-to(tablet-max) {
            margin-right: 20px;
        }
        &:last-of-type {
            margin-right: 0px;
        }
    }
}

////////////////////////////////////
.support-list {
    padding: 100px 0 200px 0;
    @include respond-to(phone-max) {
        padding: 50px 0 220px 0;
    }
}


$content-aligned-height:250px;
$content-aligned-height-taller:400px;
.content-aligned {
    padding: 0 0 100px 0;
    &:last-of-type {
        padding: 0 0 20px 0;
    }
    @include respond-to(tablet-max) {
        padding: 0 0 160px 0;
    }
    @include respond-to(phone-max) {
        padding: 0 0 140px 0;
    }
    .full-height {
        @include respond-to(tablet-min) {
            height: $content-aligned-height;
            position: relative;
        }
    }
    &.taller {
        .content-aligned-image {
            height: $content-aligned-height-taller;
        }
        .full-height {
            @include respond-to(tablet-min) {
                height: $content-aligned-height-taller;
            }
        }
    }
    .wrapper {
        display: inline-block;
        //padding-left: 20px; /*changed by Ghanshyam due to compilance monitoring padding-left: 20px;*/
        @include respond-to(tablet-min) {

            position: absolute;
            width: calc(100% - 20px);
            left: 20px;
            top: 50%;
            transform: translateY(-50%);
        }
        @include respond-to(phone-max) {
            padding-left: 0px;
        }
    }
    &-header {
        padding-right: 0px;
    }
    &-text {
        display: block;
        font-size: 14px;
        line-height: 1.71;
        color: $color_text_dark;
        margin-top: 15px;
        margin-bottom: 0px;
    }
    &-button {
        margin-top: 20px;
        @include respond-to(phone-max) {
            position: absolute;
            bottom: -260px;
        }
    }
    &-image {
        display: inline-block;
        width: 100%;
        height: $content-aligned-height;
        background-size: cover;
        background-position: center;
        position: relative;
        @include respond-to(phone-max) {
            height: 150px;
            margin-top: 20px;
        }
        &:before {
            content: "";
            display: inline-block;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0px;
            top: 0px;
            background: linear-gradient(to left, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.0));
        }
    }
    &.right {
        text-align: right;
        @include respond-to(phone-max) {
            text-align: left;
        }
        .content-aligned-image:before {
            background-image: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.0));
        }
        .wrapper {
            left: auto;
            right: 20px;
            padding-left: 0px;
            //padding-right: 20px;/*changed by Ghanshyam due to compilance monitoring padding-right: 20px;*/
        }
    }
    .content-image-height
    {
        height: 160px;
    }
    .content-image-inline
    {
        width:50%; float:right;
    }
    .content-image-align
    {
        display:flex; justify-content:center; align-items:center;
    }
    
}